import { Icon as Iconify, addIcon } from "@iconify/vue";
import { h } from "vue";
import { initClientBundle, loadIcon, useResolvedName } from "./shared.js";
import { useAsyncData, useNuxtApp, defineComponent, useAppConfig } from "#imports";
export const NuxtIconSvg = /* @__PURE__ */ defineComponent({
  name: "NuxtIconSvg",
  props: {
    name: {
      type: String,
      required: true
    },
    customize: {
      type: Function,
      required: false
    }
  },
  async setup(props, { slots }) {
    const nuxt = useNuxtApp();
    const options = useAppConfig().icon;
    const name = useResolvedName(() => props.name);
    const storeKey = "i-" + name.value;
    if (name.value) {
      if (import.meta.server) {
        useAsyncData(
          storeKey,
          () => loadIcon(name.value, options.fetchTimeout),
          { deep: false }
        );
      }
      if (import.meta.client) {
        const payload = nuxt.payload.data[storeKey];
        if (payload) {
          addIcon(name.value, payload);
        } else {
          initClientBundle(addIcon);
        }
      }
    }
    return () => h(Iconify, {
      icon: name.value,
      ssr: true,
      // Iconify uses `customise`, where we expose `customize` for consistency
      customise: props.customize
    }, slots);
  }
});
